<template>
  <div :id="id" class="form-group" :class="error[0] ? 'is-invalid' : ''">
    <label :for="id" v-if="name">
      {{ configuredName }}
      <NbIcon
        v-if="helpText"
        :id="id + 'popoverid'"
        :class="`cursor-help`"
        icon="help-circle"
      />
      <b-popover
        placement="topright"
        :target="id + 'popoverid'"
        triggers="hover focus"
        variant="dark"
      >
        {{ helpText }}
      </b-popover>
    </label>
    <div class="input-group">
      <div class="input-group-prepend" slot="prepend">
        <slot name="prepend" v-if="!prepend" />
        <div class="input-group-text" v-else>{{ prepend }}</div>
      </div>
      <slot />
      <div class="input-group-append" slot="append">
        <slot name="append" v-if="!append" />
        <div class="input-group-text" v-else>{{ append }}</div>
      </div>
    </div>
    <ErrorFeedback :error="error[0]" />
  </div>
</template>

<script>
import ErrorFeedback from "../../generic/ErrorFeedback.vue";
import baseProps from "./baseProps.js";
import NbIcon from "@/components/icons/NbIcon.vue";

export default {
  name: "BaseInput",
  components: {
    ErrorFeedback,
    NbIcon,
  },
  props: {
    ...baseProps,
    size: {
      type: String,
      default: "md",
    },
  },
  computed: {
    configuredName() {
      if (this.required) {
        return `${this.name} *`;
      }
      return this.name;
    },
  },
};
</script>

<style lang="scss">
.form-group {
  &.is-invalid {
    & > .invalid-feedback {
      display: block;
    }
  }
}
</style>
