<template>
  <BaseInput
    class="nb-international-phone-input"
    :class="classesPhoneWrapper"
    :id="id + '-phone-wrapper'"
    :name="name"
    :error="errorsFeedback"
    :required="required"
    :helpText="helpText"
    :prepend="prepend"
    :append="append"
  >
    <InputFacade
      :class="classes"
      :id="id"
      :placeholder="placeholderMask"
      :aria-required="required"
      :required="required"
      @blur="onBlur"
      v-model="textContent"
      v-facade="mask"
    />
  </BaseInput>
</template>

<script>
import { InputFacade, facade } from "vue-input-facade";
import BaseInput from "../base-input/BaseInput.vue";
import baseProps from "../baseProps.js";
import COUNTRIES from "countries-phone-masks";

export default {
  name: "NbInternationalPhoneInput",
  directives: { facade },
  components: {
    BaseInput,
    InputFacade,
  },
  props: {
    ...baseProps,
    code: {
      type: String,
      required: false,
    },
    disableOn: {
      type: [String, Number],
      required: false,
    },
    rules: {
      type: String,
      required: false,
    },
  },
  data: () => ({
    timer: null,
    textContent: "",
    countries: COUNTRIES,
    selected: "BR",
    internalCode: "+55",
    internalErrors: [],
  }),
  created() {
    this.textContent = this.value;
  },
  methods: {
    onBlur() {
      this.$emit("blur");
      this.checkRules(this.textContent);
      this.validateTextContent();
    },
    validateTextContent() {
      if (this.required && !this.textContent.length) {
        this.invalidCallback();
        return;
      }

      this.$emit("valid", this.id);
    },
    invalidCallback() {
      this.$emit("invalid", {
        id: this.id,
        message: this.errorMessage,
      });
    },
    getFlag(iso) {
      if (iso !== "AN")
        return `https://flagcdn.com/24x18/${iso.toLowerCase()}.png`;
    },
    replaceString(str) {
      let currentNum = 1;
      let finalStr = "";

      str.split("").forEach((caractere) => {
        if (caractere === "#") {
          finalStr += currentNum;
          currentNum = (currentNum % 9) + 1;
        } else {
          finalStr += caractere;
        }
      });
      return finalStr;
    },
    allOk() {
      const element = document.getElementById(`${this.id}-phone-wrapper`);
      if (this.error.length > 0 || this.internalErrors.length > 0) {
        element.classList.remove("is-valid");
        return;
      }
      setTimeout(() => {
        if (this.textContent && this.textContent.length > 0) {
          element.classList.add("is-valid");
        }
      }, 200);
    },
    checkRules(newValue) {
      this.internalErrors = [];
      this.internalErrors = this.$helpers.inputRules(
        newValue,
        this.rules,
        this.required
      );
    },
  },
  computed: {
    classes() {
      const baseClass = ["form-control"];

      if (this.error.length || this.internalErrors.length > 0) {
        baseClass.push("is-invalid");
      }
      return baseClass;
    },
    classesPhoneWrapper() {
      const baseClass = [];

      if (this.error.length || this.internalErrors.length > 0) {
        baseClass.push("is-invalid");
        return baseClass;
      }
      if (this.textContent && this.textContent.length > 0) {
        baseClass.push("is-valid");
      }
      return baseClass;
    },
    errorMessage() {
      if (this.required && !this.textContent.length) {
        return this.$t("errorMessages.required");
      }
      return this.$t("errorMessages.invalidPhone");
    },
    options() {
      return this.countries.map(({ name, iso, flag }) => ({
        value: iso,
        html: `<img src="${flag}" width="24px" height="24px">${name}`,
      }));
    },
    placeholderMask() {
      return "+" + "0".repeat(13);
    },
    mask() {
      return "+" + "#".repeat(13);
    },
    flagSelected() {
      const selectedFlag = this.countries.find(
        ({ iso }) => iso === this.selected
      ).iso;
      return `https://flagcdn.com/24x18/${selectedFlag.toLowerCase()}.png`;
    },
    errorsFeedback() {
      if (this.error.length > 0) {
        return this.error;
      }
      if (this.internalErrors.length > 0) {
        return this.internalErrors;
      }
      return [];
    },
  },
  watch: {
    textContent(newValue) {
      this.$emit("input", newValue);
      this.validateTextContent();
      //this.allOk();
    },
    value(newValue) {
      if (newValue !== this.textContent) {
        this.textContent = newValue;
      }
    },
    code() {
      this.getCountryFromCode();
    },
    /* internalErrors() {
      this.allOk();
    }, */
  },
};
</script>

<style>
.text-black {
  color: black;
}

.nb-international-phone-input .countries-list {
  flex: 0;
}

.nb-international-phone-input .countries-list button {
  background: var(--gray-10) 0% 0% no-repeat padding-box !important;
  border-left: 0px solid !important;
  border-right: 0px solid !important;
  border-top: 0px solid !important;
  border-bottom: 2px solid var(--black) !important;
  max-width: 120px !important;
  padding: 0 0.5rem;
  border-radius: 4px 4px 0px 0px;
}

.nb-international-phone-input.is-invalid .countries-list button {
  border-bottom: 2px solid var(--error) !important;
  caret-color: var(--error);
}

.nb-international-phone-input.is-valid .countries-list button {
  border-bottom: 2px solid var(--success) !important;
  caret-color: var(--success);
}

.nb-international-phone-input .countries-list button:hover {
  background: var(--gray-20) 0% 0% no-repeat padding-box !important;
}

.nb-international-phone-input .countries-list button::after {
  color: var(--black);
  margin-bottom: -2px;
  margin-left: 2px;
}

.nb-international-phone-input .countries-list button:focus {
  box-shadow: none !important;
}

.nb-international-phone-input .countries-list .dropdown-menu {
  background: var(--gray-05) 0% 0% no-repeat padding-box !important;
  overflow: auto;
  max-height: 53vh;
}

.nb-international-phone-input .countries-list .dropdown-menu .dropdown-item {
  cursor: pointer;
  padding: 9px 12px;
  border-bottom: 2px solid var(--gray-10);
}

.nb-international-phone-input
  .countries-list
  .dropdown-menu
  .dropdown-item:hover {
  background: var(--gray-10) !important;
}

.nb-international-phone-input .country-flag {
  width: 30px;
}

.nb-international-phone-input label {
  color: var(--black);
  text-align: left;
  font: normal normal 600 12px/16px var(--font-family-base);
  letter-spacing: 0px;
  display: inline-block;
  margin-bottom: 0.5rem;
  cursor: default;
}

.nb-international-phone-input .form-control:not(:placeholder-shown),
.nb-international-phone-input.is-invalid .form-control:not(:placeholder-shown) {
  border-bottom: 2px solid var(--black);
}

.nb-international-phone-input .form-control {
  transition: all 0.3s ease;
  height: 2.5rem;
  padding: 9px 12px;
  box-shadow: none;
  border: none;
  border-bottom: 2px solid var(--gray-40);
  font: normal normal normal 14px/20px var(--font-family-base);
  background: var(--gray-05) 0% 0% no-repeat padding-box !important;
  border-radius: 4px 4px 0px 0px;
}

.nb-international-phone-input .form-control:hover {
  background: var(--gray-10) 0% 0% no-repeat padding-box !important;
}

.nb-international-phone-input .form-control:focus {
  box-shadow: none;
  border-bottom: 2px solid var(--black);
}

.nb-international-phone-input .form-control::placeholder {
  color: var(--gray-40);
}

.nb-international-phone-input.is-invalid .form-control {
  border-bottom: 2px solid var(--error);
  caret-color: var(--error);
  background-image: none;
}

.nb-international-phone-input.is-valid .form-control {
  border-bottom: 2px solid var(--success) !important;
  caret-color: var(--success) !important;
  background-image: none;
}

.nb-international-phone-input.is-invalid .form-control:hover {
  background: var(--gray-10) 0% 0% no-repeat padding-box !important;
}

.nb-international-phone-input .form-control:disabled,
.nb-international-phone-input .form-control[disabled] {
  background-color: var(--gray-05) !important;
  color: var(--gray-20) !important;
  border-bottom: 2px solid var(--gray-20);
  cursor: not-allowed;
}
</style>
