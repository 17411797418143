import HttpService from "./HttpService";

export default class CustomermerService {
  constructor() {
    this.httpService = HttpService;
  }

  /**
   * Get orders
   *
   * @param filters
   * @returns {Promise}
   */
  getCustomers(filters = {}) {
    return this.httpService.get("/v1/customers", {
      params: filters,
    });
  }

  getCustomer(id) {
    return this.httpService.get("/v1/customers/" + id);
  }

  updateCustomer(id, data) {
    return this.httpService.put("/v1/customers/" + id, data);
  }

  removeCustomer(id) {
    return this.httpService.delete("/v1/customers/" + id);
  }

  removeMassiveCustomer(data) {
    return this.httpService.delete("/v1/customers/massive_destroy/" + data);
  }

  createCustomer(data) {
    return this.httpService.post("/v1/customers", data);
  }

  /**
   *
   * @param {*} filters
   * @returns {Promise}
   */
  downloadCustomers(filters = {}) {
    return this.httpService.get("/v1/customer/download", {
      params: filters,
    });
  }

  uploadCustomers(data) {
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    return this.httpService.post("/v1/customers/import", data, config);
  }

  delete_all() {
    return this.httpService.delete("/v1/customers/delete_all");
  }
}
